import React from "react";
import styled from "../../../../styles/editorPage/rightBarEditor.module.scss";
import { Checkbox } from "antd";

/**
 * @author Name Surname
 * @created 11.11.2024
 * @description
 * @last-updated
 * @update-description
 * @todo
 * @fixme
 * @param props
 * @constructor
 */
function SettingInfoOutside(props) {
	const { active, changeParams, isReplacement } = props;

	return (
		<>
			{active !== undefined ? (
				<div
					className={styled.rightStylesMainMargin}
					key={active}
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<div style={{ fontWeight: 500 }}>Элементы с наружи</div>
					<div className={styled.checkbox}>
						<Checkbox
							checked={active}
							onChange={(e) =>
								changeParams(
									"elementOutside",
									e.target.checked,
									isReplacement
								)
							}
						>
							Включить
						</Checkbox>
					</div>
				</div>
			) : null}
		</>
	);
}

export default SettingInfoOutside;
