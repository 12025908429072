import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useInView } from "react-intersection-observer";
import BorderActive from "../../workFields/BorderActive";
import { useBorderActive } from "../../../../hooks/useBorderActive";
import sliderLastBlock from "../../../../assets/sliderLastBlock.jpg";
import styled from "../../../../styles/editorPage/workField.module.scss";

/**
 * @author Zholaman Zhumanov
 * @created 24.07.2024
 * @description
 * @last-updated
 * @update-description
 * @todo
 * @fixme
 * @param props
 * @constructor
 */
function SwiperNewCategory(props) {
	const { mainId, component, collection, isActivePage } = props;

	const [data, setData] = useState([]);
	const [swiper, setSwiper] = useState(null);

	const [refInView, inView] = useInView({ threshold: 0.1 });

	const { width, height, isHide, idActive, selectMainComponent, ref, resize } =
		useBorderActive(component, [component?.id]);

	const select = (e) => {
		resize();
		selectMainComponent(e);
	};

	const getAnimateFlash = () => {
		if (component.animate === undefined || component.animate.showFlash) {
			return inView ? styled.mosaicCardShow : styled.mosaicCardHide;
		}
		return "";
	};

	/**
	 * @description Получение данных список товара
	 * @returns {Promise<void>}
	 */
	const getParams = async () => {
		let myHeaders = new Headers();
		myHeaders.append(
			"User-Agent",
			"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36"
		);

		let requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		const res = await fetch(
			`https://api.lichi.com/category/get_category_product_list?category=${component.category}&limit=${component.count}&lang=${component.lang}&shop=${component.shop}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => result)
			.catch((error) => console.error("error", error));
		setData(res.api_data.aProduct);
	};

	useEffect(() => {
		getParams();
	}, [component?.category, component.count, component.lang, component.shop]);

	useEffect(() => {
		if (swiper) {
			swiper.slidePerGroup = component?.slidePerGroup;
			swiper.updateSize();
		}
	}, [swiper, component?.slidePerGroup]);

	return (
		<div className="p-relative" ref={ref}>
			{!isHide &&
				idActive.length === 1 &&
				idActive[0] === component.id &&
				!isActivePage && (
					<BorderActive
						width={width}
						height={height}
						id={[mainId]}
						collection={collection}
						btnDown
						container
					/>
				)}
			<div
				onMouseDown={select}
				onContextMenu={(e) => e.preventDefault()}
				ref={refInView}
				className={`${styled.swiperContainer} ${getAnimateFlash()}`}
				style={{
					width: "100%",
					height: "100%",
					backgroundColor: "#ffffff",
				}}
			>
				<Swiper
					className="mySwiper"
					onSwiper={setSwiper}
					controller={{ control: swiper }}
					slidesPerView={component?.slidePerView}
					spaceBetween={+component?.styles?.spaceBetween || 2}
					slidesPerGroup={component?.slidePerGroup || 4}
					speed={component?.autoplay?.speed}
					style={{
						...component?.styles,
						marginBottom: component?.styles?.marginBottom,
						padding: component?.styles?.padding?.join(" "),
					}}
				>
					{data.map((item, key) => {
						return (
							<SwiperSlide key={key}>
								<div
									style={{
										width: "auto",
										boxSizing: "border-box",
									}}
								>
									<img
										src={item?.photos?.[0]?.thumbs?.["384_512"]}
										alt=""
										style={{
											width: "100%",
											display: "block",
										}}
									/>

									<div style={{ width: "100%" }}>
										<div
											style={{
												...component?.nameStyles,
												margin:
													component?.nameStyles?.margin?.join(" "),
												display: "block",
												whiteSpace: "nowrap",
												textOverflow: "ellipsis",
												overflow: "hidden",
											}}
										>
											{item?.name}
										</div>
										<div
											style={{
												...component?.priceStyles,
												margin:
													component?.priceStyles?.margin?.join(
														" "
													),
											}}
										>
											{item?.format_price?.[1]}
										</div>
									</div>
								</div>
							</SwiperSlide>
						);
					})}

					<SwiperSlide>
						<div className="p-relative">
							<div className={styled.itemContainer}>
								<div className={styled.imgSize}>
									<img
										src={sliderLastBlock}
										alt=""
										style={{ width: "100%" }}
									/>
								</div>
							</div>
						</div>
					</SwiperSlide>
				</Swiper>
			</div>
		</div>
	);
}

export default SwiperNewCategory;
