import React from "react";
import { useBorderActive } from "../../../../hooks/useBorderActive";
import styled from "../../../../styles/editorPage/workField.module.scss";
import BorderActive from "../../workFields/BorderActive";

/**
 * @author Name Surname
 * @created 06.11.2024
 * @description
 * @last-updated
 * @update-description
 * @todo
 * @fixme
 * @param props
 * @constructor
 */
function LogoElement(props) {
	const { mainId, blockId, component, isActivePage, collection } = props;

	const { width, height, isHide, ref, resize, idActive, selectThisComponent } =
		useBorderActive(component, [mainId, blockId, component.id]);

	const select = (e) => {
		resize();
		selectThisComponent(e);
	};

	return (
		<div
			className={styled.frontSpaceWrapper}
			style={{
				alignSelf: component?.styles.alignSelf,
			}}
		>
			<div
				className="p-relative"
				style={{
					margin: component.styles.margin.join(" "),
				}}
			>
				{!isHide &&
					idActive.length === 3 &&
					component.id === idActive[2] &&
					!isActivePage && (
						<BorderActive
							width={width}
							height={height}
							id={[mainId, blockId, component.id]}
							large
							collection={collection}
							container
						/>
					)}
				<div ref={ref} onClick={select}>
					<img
						src={component?.image?.url}
						alt=""
						style={{
							display: "block",
							height: "auto",
							width: component?.styles?.width
								? component?.styles?.width
								: "100%",
						}}
					/>
				</div>
			</div>
		</div>
	);
}

export default LogoElement;
