import React from "react";
import styled from "../../../../styles/editorPage/rightBarEditor.module.scss";
import { Checkbox } from "antd";

/**
 * @author Name Surname
 * @created 08.11.2024
 * @description
 * @last-updated
 * @update-description
 * @todo
 * @fixme
 * @param props
 * @constructor
 */
function SettingRandomCheck(props) {
	const { randomShowComponent, changeParams, isReplacement } = props;

	return (
		<>
			{randomShowComponent !== undefined ? (
				<div
					className={styled.rightStylesMainMargin}
					key={randomShowComponent}
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<div style={{ fontWeight: 500 }}>
						Показать в случайном порядке
					</div>
					<div className={styled.checkbox}>
						<Checkbox
							checked={randomShowComponent}
							onChange={(e) =>
								changeParams(
									"randomShowComponent",
									e.target.checked,
									isReplacement
								)
							}
						>
							Включить
						</Checkbox>
					</div>
				</div>
			) : null}
		</>
	);
}

export default SettingRandomCheck;
